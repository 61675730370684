(function ($) {
  Drupal.behaviors.contentBlockFormatterV1 = {
    attach: function (context) {
      var animationTime = 300;
      var $modules = $('.js-content-block-formatter--v1', context);
      var $readMore = $('.js-grid-read-more', context);

      $readMore.on('click.gridReadMore', function (event) {
        event.preventDefault();
        var $link = $(this).closest('.js-accordion-content').find('a');

        if ($link.length) {
          $link.trigger('click');
        }
      });

      $modules.each(function () {
        var $module = $(this);
        var $triggers = $('.js-accordion-trigger', $module);

        $triggers.each(function () {
          var text = $(this).parent().find('.js-content-block-header').text();

          if (text) {
            $(this).text(text);
          }
        });
        $triggers.on('click.expand', function (event) {
          $(this).toggleClass('active');
          $(this).parent().find('.js-accordion-content').stop(true, true).slideToggle(animationTime);
        });
      });
    }
  };
})(jQuery);
